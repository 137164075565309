import React, { useEffect, useReducer, useCallback } from "react";
import axios from "axios";
import { server } from "..";
import GlobalContext from "./globalContext";
import { useAuth } from "./AuthContext";

const initialState = {
  blogs: [],
  pages: [],
  banners: [],
  blogCategories: [],
  titles: [],
  contacts: [],
  faqs: [],
  logos: [],
  subscribers: [],
  users: [],
  titleDescriptionImages: [],
  settings: [], // New state for settings
  profile: null, // New state for profile
  contextLoading: false,
  error: null,
};

// Reducer function with new cases for settings, title-description-image, and profile
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_BLOGS_REQUEST":
    case "FETCH_PAGES_REQUEST":
    case "FETCH_BANNERS_REQUEST":
    case "FETCH_BLOG_CATEGORY_REQUEST":
    case "FETCH_TITLES_REQUEST":
    case "FETCH_CONTACTS_REQUEST":
    case "FETCH_FAQS_REQUEST":
    case "FETCH_LOGOS_REQUEST":
    case "FETCH_SUBSCRIBERS_REQUEST":
    case "FETCH_USERS_REQUEST":
    case "FETCH_TITLE_DESCRIPTION_IMAGES_REQUEST":
    case "FETCH_SETTINGS_REQUEST":
    case "FETCH_PROFILE_REQUEST": // New request action for profile
      return { ...state, contextLoading: true, error: null };

    case "FETCH_BLOGS_SUCCESS":
      return { ...state, contextLoading: false, blogs: action.payload };
    case "FETCH_PAGES_SUCCESS":
      return { ...state, contextLoading: false, pages: action.payload };
    case "FETCH_BANNERS_SUCCESS":
      return { ...state, contextLoading: false, banners: action.payload };
    case "FETCH_BLOG_CATEGORY_SUCCESS":
      return {
        ...state,
        contextLoading: false,
        blogCategories: action.payload,
      };
    case "FETCH_TITLES_SUCCESS":
      return { ...state, contextLoading: false, titles: action.payload };
    case "FETCH_CONTACTS_SUCCESS":
      return { ...state, contextLoading: false, contacts: action.payload };
    case "FETCH_FAQS_SUCCESS":
      return { ...state, contextLoading: false, faqs: action.payload };
    case "FETCH_LOGOS_SUCCESS":
      return { ...state, contextLoading: false, logos: action.payload };
    case "FETCH_SUBSCRIBERS_SUCCESS":
      return { ...state, contextLoading: false, subscribers: action.payload };
    case "FETCH_USERS_SUCCESS":
      return { ...state, contextLoading: false, users: action.payload };
    case "FETCH_TITLE_DESCRIPTION_IMAGES_SUCCESS":
      return {
        ...state,
        contextLoading: false,
        titleDescriptionImages: action.payload,
      };
    case "FETCH_SETTINGS_SUCCESS":
      return { ...state, contextLoading: false, settings: action.payload };
    case "FETCH_PROFILE_SUCCESS": // New success action for profile
      return { ...state, contextLoading: false, profile: action.payload };

    case "FETCH_BLOGS_FAILURE":
    case "FETCH_PAGES_FAILURE":
    case "FETCH_BANNERS_FAILURE":
    case "FETCH_BLOG_CATEGORY_FAILURE":
    case "FETCH_TITLES_FAILURE":
    case "FETCH_CONTACTS_FAILURE":
    case "FETCH_FAQS_FAILURE":
    case "FETCH_LOGOS_FAILURE":
    case "FETCH_SUBSCRIBERS_FAILURE":
    case "FETCH_USERS_FAILURE":
    case "FETCH_TITLE_DESCRIPTION_IMAGES_FAILURE":
    case "FETCH_SETTINGS_FAILURE":
    case "FETCH_PROFILE_FAILURE": // New failure action for profile
      return { ...state, contextLoading: false, error: action.payload };

    default:
      return state;
  }
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuth();

  // Fetch data for multiple entities, including the new settings
  const fetchData = useCallback(async () => {
    dispatch({ type: "FETCH_BLOGS_REQUEST" });
    dispatch({ type: "FETCH_PAGES_REQUEST" });
    dispatch({ type: "FETCH_BANNERS_REQUEST" });
    dispatch({ type: "FETCH_BLOG_CATEGORY_REQUEST" });
    dispatch({ type: "FETCH_TITLES_REQUEST" });
    dispatch({ type: "FETCH_CONTACTS_REQUEST" });
    dispatch({ type: "FETCH_FAQS_REQUEST" });
    dispatch({ type: "FETCH_LOGOS_REQUEST" });
    dispatch({ type: "FETCH_SUBSCRIBERS_REQUEST" });
    dispatch({ type: "FETCH_TITLE_DESCRIPTION_IMAGES_REQUEST" });
    dispatch({ type: "FETCH_SETTINGS_REQUEST" });

    try {
      const [
        blogsResponse,
        pagesResponse,
        bannersResponse,
        blogCategoriesResponse,
        titlesResponse,
        contactsResponse,
        faqsResponse,
        logosResponse,
        subscribersResponse,
        titleDescriptionImagesResponse,
        settingsResponse,
      ] = await Promise.all([
        axios.get(`${server}/all-blogs`),
        axios.get(`${server}/pages`),
        axios.get(`${server}/banners`),
        axios.get(`${server}/blog-categories`),
        axios.get(`${server}/all-titles`),
        axios.get(`${server}/all-contact`),
        axios.get(`${server}/all-faq`),
        axios.get(`${server}/all-logo`),
        axios.get(`${server}/subscriber`),
        axios.get(`${server}/title-description-image/all`),
        axios.get(`${server}/setting`),
      ]);

      dispatch({
        type: "FETCH_BLOGS_SUCCESS",
        payload: blogsResponse.data.data,
      });
      dispatch({
        type: "FETCH_PAGES_SUCCESS",
        payload: pagesResponse.data.data,
      });
      dispatch({
        type: "FETCH_BANNERS_SUCCESS",
        payload: bannersResponse.data.data,
      });
      dispatch({
        type: "FETCH_BLOG_CATEGORY_SUCCESS",
        payload: blogCategoriesResponse.data.data,
      });
      dispatch({
        type: "FETCH_TITLES_SUCCESS",
        payload: titlesResponse.data.data,
      });
      dispatch({
        type: "FETCH_CONTACTS_SUCCESS",
        payload: contactsResponse.data.data,
      });
      dispatch({ type: "FETCH_FAQS_SUCCESS", payload: faqsResponse.data.data });
      dispatch({
        type: "FETCH_LOGOS_SUCCESS",
        payload: logosResponse.data.data,
      });
      dispatch({
        type: "FETCH_SUBSCRIBERS_SUCCESS",
        payload: subscribersResponse.data.data,
      });
      dispatch({
        type: "FETCH_TITLE_DESCRIPTION_IMAGES_SUCCESS",
        payload: titleDescriptionImagesResponse.data.data,
      });
      dispatch({
        type: "FETCH_SETTINGS_SUCCESS",
        payload: settingsResponse.data.data,
      });
    } catch (error) {
      dispatch({ type: "FETCH_BLOGS_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_PAGES_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_BANNERS_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_BLOG_CATEGORY_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_TITLES_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_CONTACTS_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_FAQS_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_LOGOS_FAILURE", payload: error.message });
      dispatch({ type: "FETCH_SUBSCRIBERS_FAILURE", payload: error.message });
      dispatch({
        type: "FETCH_TITLE_DESCRIPTION_IMAGES_FAILURE",
        payload: error.message,
      });
      dispatch({
        type: "FETCH_SETTINGS_FAILURE",
        payload: error.message,
      });
      console.error("Failed to fetch data:", error);
    }
  }, []);

  // Fetch authenticated user data
  const fetchUsers = useCallback(async () => {
    if (!isAuthenticated) {
      console.log("User is not authenticated, not fetching users");
      return;
    }

    dispatch({ type: "FETCH_USERS_REQUEST" });

    try {
      const response = await axios.get(`${server}/all-user`, {
        withCredentials: true,
      });
      dispatch({ type: "FETCH_USERS_SUCCESS", payload: response.data.data });
    } catch (error) {
      console.error("Failed to fetch users:", error);
      dispatch({ type: "FETCH_USERS_FAILURE", payload: error.message });
    }
  }, [isAuthenticated]);

  // Fetch profile data (authenticated route)
  const fetchProfile = useCallback(async () => {
    if (!isAuthenticated) {
      console.log("User is not authenticated, not fetching profile");
      return;
    }

    dispatch({ type: "FETCH_PROFILE_REQUEST" });

    try {
      const response = await axios.get(`${server}/me`, {
        withCredentials: true,
      });
      dispatch({ type: "FETCH_PROFILE_SUCCESS", payload: response.data.data });
    } catch (error) {
      console.error("Failed to fetch profile:", error);
      dispatch({ type: "FETCH_PROFILE_FAILURE", payload: error.message });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchData();
    fetchProfile(); // Fetch profile data after authentication
  }, [fetchData, fetchProfile]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, isAuthenticated]);

  return (
    <GlobalContext.Provider
      value={{
        blogsData: state.blogs,
        pagesData: state.pages,
        bannersData: state.banners,
        blogCategoriesData: state.blogCategories,
        titlesData: state.titles,
        contactsData: state.contacts,
        faqsData: state.faqs,
        logosData: state.logos,
        subscribersData: state.subscribers,
        usersData: state.users,
        titleDescriptionImagesData: state.titleDescriptionImages,
        settingsData: state.settings,
        profileData: state.profile,
        contextLoading: state.contextLoading,
        contextError: state.error,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
