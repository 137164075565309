import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h1>
        Oops<span>!</span>
      </h1>
      <p>404 - PAGE NOT FOUND</p>
      <Link to={"/"}>
        GO TO HOME PAGE <FaArrowRight />{" "}
      </Link>
    </div>
  );
};

export default PageNotFound;
