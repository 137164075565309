import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  FaBlogger,
  FaUserCheck,
  FaQuestionCircle,
  FaImages,
} from "react-icons/fa";
import {
  MdContactPhone,
  MdChangeCircle,
  MdDashboard,
  MdSubscriptions,
  MdContactPage,
  MdOutlineSubtitles,
} from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";
import { PiFlagBannerFill } from "react-icons/pi";
import { IoLogoBuffer } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import GlobalContext from "../../context/globalContext";

const Sidebar = ({ isOpen, setIsOpen }) => {
  const { profileData } = useContext(GlobalContext);

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-content d-flex flex-column fs-4">
        <NavLink
          to={"/admin/dashboard"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <MdDashboard />
          <span>Dashboard</span>
        </NavLink>

        <NavLink
          to={"/admin/banner"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <PiFlagBannerFill />
          <span>Banners</span>
        </NavLink>

        <NavLink
          to={"/admin/blogs"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <FaBlogger />
          <span>Blogs</span>
        </NavLink>

        <NavLink
          to={"/admin/pages"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <MdContactPage />
          <span>Pages</span>
        </NavLink>

        <NavLink
          to={"/admin/blog-categories"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <TbCategoryPlus />
          <span>Blog Categories</span>
        </NavLink>

        <NavLink
          to={"/admin/title-description"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <MdOutlineSubtitles />
          <span>Title and Description</span>
        </NavLink>

        <NavLink
          to={"/admin/title-description-image"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <FaImages />
          <span>Title, Description and Image</span>
        </NavLink>

        <NavLink
          to={"/admin/all-faq"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <FaQuestionCircle />
          <span>Faq's</span>
        </NavLink>

        {profileData?.role === "admin" ? (
          <NavLink
            to={"/admin/all-users"}
            className="sidebar-item"
            onClick={() => setIsOpen(false)}
          >
            <FaUserCheck />
            <span>Registered Users</span>
          </NavLink>
        ) : null}

        <NavLink
          to={"/admin/subscriber"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <MdSubscriptions />
          <span>Subscriber</span>
        </NavLink>

        <NavLink
          to={"/admin/all-logo"}
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <IoLogoBuffer />
          <span>Partner's Logo</span>
        </NavLink>

        <NavLink
          to="/admin/user-contact"
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <MdContactPhone />
          <span>User Contact</span>
        </NavLink>

        <NavLink
          to="/admin/change-password"
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <MdChangeCircle />
          <span>Change Password</span>
        </NavLink>

        <NavLink
          to="/admin/setting"
          className="sidebar-item"
          onClick={() => setIsOpen(false)}
        >
          <IoSettingsSharp />
          <span>Setting</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
