import React, { useRef, useState } from "react";
import { IMG } from "../../assets/img";
import { Link, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { RiLogoutBoxLine } from "react-icons/ri";
import { RiCloseLargeLine } from "react-icons/ri";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import axios from "axios";
import { server } from "../..";
import { FaBarsStaggered } from "react-icons/fa6";

const DashboardHeader = ({ toggleSidebar, isOpen }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${server}/logout`,
        {},
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setSuccess("Logged out successfully!");
        navigate("/admin/login");
      } else {
        setError("Logout failed. Please try again.");
      }
      window.location.reload();
    } catch (err) {
      console.error("Logout error:", err);
      setError(
        err.response?.data?.message || "Logout failed. Please try again."
      );
    }
  };

  return (
    <div className="dashboard-header">
      <div>
        <button className="toggle-button" onClick={toggleSidebar}>
          {isOpen ? <RiCloseLargeLine /> : <FaBarsStaggered />}
        </button>
        <img src={IMG.HeaderLogo} alt="logo" />
      </div>
      <div>
        <span className="profile-dropdown">
          <FaRegUser />
          {openDropdown ? (
            <MdKeyboardArrowUp onClick={toggleDropdown} />
          ) : (
            <MdKeyboardArrowDown onClick={toggleDropdown} />
          )}
          <ul
            className={`${
              openDropdown
                ? "profile-dropdown-items-show"
                : "profile-dropdown-items"
            }`}
          >
            <li>
              <CgProfile />
              <Link
                to={"/admin/profile"}
                onClick={() => setOpenDropdown(false)}
              >
                Profile
              </Link>
            </li>
            <li>
              <RiLogoutBoxLine />
              <button onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        </span>
      </div>
    </div>
  );
};

export default DashboardHeader;
