import React, { useState } from "react";
import DashboardHeader from "../pages/admin/DashboardHeader";
import Sidebar from "../pages/admin/Sidebar";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} setIsOpen={setIsOpen} />
      <DashboardHeader toggleSidebar={toggleSidebar} isOpen={isOpen} setIsOpen={setIsOpen} />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
