import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  FaArrowRight,
  FaCheck,
  FaMobileRetro,
  FaLocationDot,
} from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import GlobalContext from "../context/globalContext";
import Loader from "../components/Loader";

const Footer = () => {
  const { contextLoading } = useContext(GlobalContext);
  return (
    <>
      {contextLoading ? (
        <Loader />
      ) : (
        <footer className="fluid-container">
          <div className="footer-content">
            <div className="footer-contact-sec">
              <div className="footer-contact-top">
                <div>
                  <div className="footer-contact-top-icon">
                    <FaMobileRetro />
                  </div>
                </div>
                <h3>Get best rate for your freight transport</h3>
              </div>
              <p>
                24/7 customer support and expert advice. Up to 70% savings on
                shipping costs with all major carriers
              </p>
              <div className="footer-contact-num">
                <button>
                  Get the Best Rate from Competetive Market
                  <span>
                    <FaCheck />
                  </span>
                </button>
              </div>
            </div>
            <div id="footer-industry">
              <div className="footer-links-section">
                <h3>Industry Served</h3>
                <div>
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Frozen Food
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Automobile
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Import/Export
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Machineries
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Cargo Freight
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"/warehousing-and-fulfillment-center"}>
                      <FaArrowRight /> &nbsp; Warehousing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer-company">
              <div className="footer-links-section">
                <h3>Company Info</h3>
                <div>
                  <div>
                    <Link to={"/about-fulfillment-services"}>
                      <FaArrowRight /> &nbsp; About Us
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Our Expertise
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"/fullfillment-calculator"}>
                      <FaArrowRight /> &nbsp; Calculator
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"/integrations"}>
                      <FaArrowRight /> &nbsp; Our Integrations
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Terms & Conditions
                    </Link>
                  </div>
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer-served">
              <div className="footer-links-section">
                <h3>Industry Served</h3>
                <div>
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Less Than Truckload
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Road Freight Shipping
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Air Freight Shipping
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Intermodal Services
                    </Link>
                  </div>{" "}
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Fulfillment by Amazon
                    </Link>
                  </div>
                  <div>
                    <Link to={"#"}>
                      <FaArrowRight /> &nbsp; Walmart Prep Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row footer-middle-container">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
              <div className="footer-middle-section">
                <div>
                  <FaEnvelope />
                </div>
                <div>
                  <p>E-Mail Us</p>
                  <Link to="mail-to:info@titanfulfillment.com">
                    info@titanfulfillment.com
                  </Link>
                </div>
              </div>
            </div>{" "}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
              <div className="footer-middle-section">
                <div>
                  <IoCall />
                </div>
                <div>
                  <p>Support 24/7</p>
                  <Link>+1(844) 601-2444</Link>
                </div>
              </div>
            </div>{" "}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
              <div className="footer-middle-section">
                <div>
                  <FaLocationDot />
                </div>
                <div>
                  <p>Our Headquater</p>
                  <Link>NewYork, Marlboro, Houston</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom-container">
            <p>© 2024 Titan Fulfillment / All Rights Reserved</p>
            <Link to="#">Sitemap / Contact Us</Link>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
