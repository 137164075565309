import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";

const HeaderPhone = ({ menuopen, setMenuopen }) => {
  const [dropdownopen, setDropdownOpen] = useState(false);

  return (
    <div
      className={`header-nav-section ${
        menuopen ? "header-nav-section-comes" : ""
      }`}
    >
      <div className="header-links">
        <NavLink
          onClick={() => setMenuopen(false)}
          className="header-link"
          to={"/"}
        >
          Home
        </NavLink>
        <NavLink
          onClick={() => setMenuopen(false)}
          className="header-link"
          to={"/about-fulfillment-services"}
        >
          About Us
        </NavLink>

        <div
          className="dropdown-button-phone"
          onClick={() => setDropdownOpen(!dropdownopen)}
        >
          Our Services
          <MdKeyboardArrowDown
            style={dropdownopen ? { transform: "rotate(180deg)" } : {}}
          />
          <ul
            className={`dropdown-items-phone ${
              dropdownopen ? "dropdown-items-show" : ""
            }`}
          >
            <li>
              <NavLink
                onClick={() => setMenuopen(false)}
                className="header-link"
                to={"/fulfillment-services"}
              >
                Fulfillment Services
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setMenuopen(false)}
                className="header-link"
                to={"/warehousing-and-fulfillment-center"}
              >
                Warehousing Services
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setMenuopen(false)}
                className="header-link"
                to={"/amazon-prep-services"}
              >
                Amazon Prep Services
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setMenuopen(false)}
                className="header-link"
                to={"/walmart-prep-services"}
              >
                Walmart Prep Services
              </NavLink>
            </li>
          </ul>
        </div>

        <NavLink
          onClick={() => setMenuopen(false)}
          className="header-link"
          to={"/integrations"}
        >
          Integrations
        </NavLink>
        <NavLink
          onClick={() => setMenuopen(false)}
          className="header-link"
          to={"/pricing"}
        >
          Pricing
        </NavLink>
        <NavLink
          onClick={() => setMenuopen(false)}
          className="header-link"
          to={"/blog"}
        >
          Blog
        </NavLink>
        <NavLink
          onClick={() => setMenuopen(false)}
          className="header-link"
          to={"/contact"}
        >
          Contact Us
        </NavLink>
        <Link
          to={"https://app.extensiv.com/login"}
          onClick={() => setMenuopen(false)}
          className="client-login"
          target="blank"
        >
          Client Login
        </Link>
      </div>
    </div>
  );
};

export default HeaderPhone;
