import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalContextProvider from "./context/GlobalContextProvider";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </AuthProvider>
);

reportWebVitals();

export const server = "https://titan-backend-three.vercel.app/api";

// export const server = "http://localhost:6789/api";
// export const server = "https://www.titanfulfillment.com/api";
