import React, { useContext, useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import HeaderPhone from "./HeaderPhone";
import ScrollToTop from "../components/ScrollToTop";
import GlobalContext from "../context/globalContext";
import Loader from "../components/Loader";
import MoveToTop from "../components/MoveToTop";

const MainLayout = () => {
  const [menuopen, setMenuopen] = useState(false);
  const { contextLoading } = useContext(GlobalContext);

  return (
    <div>
      {contextLoading ? (
        <Loader />
      ) : (
        <Header menuopen={menuopen} setMenuopen={setMenuopen} />
      )}
      <HeaderPhone menuopen={menuopen} setMenuopen={setMenuopen} />
      <ScrollToTop />

      <Outlet />
      <MoveToTop />
      <Footer />
    </div>
  );
};

export default MainLayout;
